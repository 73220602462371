/* eslint-disable */
import request from '@/api/config.js'
import { filteringTime } from './utils'
import store from '@/store'

'use strict';

/*
	This class implements interaction with UDF-compatible datafeed.

	See UDF protocol reference at
	https://github.com/tradingview/charting_library/wiki/UDF
*/
var formatData = (data, day) => {
	var bars = []

	try {
		if (Array.isArray(data)) {
			data.forEach(item => {
				// var time = day !== '1D' ? item.time_open * 1000 : item.time_open * 1000 + 8 * 60 * 60 * 1000;
				let bar = {
					time: item.time_open * 1000,
					close: +item.price_close,
					open: +item.price_open,
					high: +item.price_high,
					low: +item.price_low,
					volume: +item.trades_number
				}
				bars.push(bar)
			})
		}
	} catch (e) {
		console.error('kline data:', e)
	}
	return bars
}
var Datafeeds = {};

Datafeeds.UDFCompatibleDatafeed = function (datafeedURL, updateFrequency, protocolVersion) {
	this._datafeedURL = datafeedURL;
	this._configuration = undefined;

	this._symbolSearch = null;
	this._symbolsStorage = null;
	this._barsPulseUpdater = new Datafeeds.DataPulseUpdater(this, updateFrequency || 10 * 1000);
	this._quotesPulseUpdater = new Datafeeds.QuotesPulseUpdater(this);
	this._protocolVersion = protocolVersion || 2;

	this._enableLogging = false;
	this._initializationFinished = false;
	this._callbacks = {};

	this._initialize();
};

Datafeeds.UDFCompatibleDatafeed.prototype.defaultConfiguration = function () {
	return {
		supports_search: true,
		supports_group_request: false,
		supported_resolutions: ['1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M'],
		supports_marks: false,
		supports_timescale_marks: false,
		supports_time: false
	};
};

Datafeeds.UDFCompatibleDatafeed.prototype.getServerTime = function (callback) {
	if (this._configuration.supports_time) {
		return this._send(this._datafeedURL + '/time', {})
			.then(function (response) {
				callback(+response);
			})
			.catch(function () {

			});
	}
};

Datafeeds.UDFCompatibleDatafeed.prototype.on = function (event, callback) {

	if (!this._callbacks.hasOwnProperty(event)) {
		this._callbacks[event] = [];
	}

	this._callbacks[event].push(callback);
	return this;
};

Datafeeds.UDFCompatibleDatafeed.prototype._fireEvent = function (event, argument) {
	if (this._callbacks.hasOwnProperty(event)) {
		var callbacksChain = this._callbacks[event];
		for (var i = 0; i < callbacksChain.length; ++i) {
			callbacksChain[i](argument);
		}

		this._callbacks[event] = [];
	}
};

Datafeeds.UDFCompatibleDatafeed.prototype.onInitialized = function () {
	this._initializationFinished = true;
	this._fireEvent('initialized');
};

Datafeeds.UDFCompatibleDatafeed.prototype._logMessage = function (message) {
	if (this._enableLogging) {
		var now = new Date();
		console.log(now.toLocaleTimeString() + '.' + now.getMilliseconds() + '> ' + message);
	}
};

Datafeeds.UDFCompatibleDatafeed.prototype._send = function (url, params) {
	// var request = url;
	// if (params) {
	// 	for (var i = 0; i < Object.keys(params).length; ++i) {
	// 		var key = Object.keys(params)[i];
	// 		var value = encodeURIComponent(params[key]);
	// 		request += (i === 0 ? '?' : '&') + key + '=' + value;
	// 	}
	// }

	this._logMessage('New request: ' + request);

	return request.get(url, { params })
};

Datafeeds.UDFCompatibleDatafeed.prototype._initialize = function () {
	setTimeout(this._setupWithConfiguration.bind(this, {
		"max_bars": 10080,
		"supports_marks": false,
		"has_daily": true,
		"supports_time": false,
		"exchanges": [],
		"supports_group_request": false,
		"supports_search": true,
		"symbols_types": [{
			"name": "BTC/USDT",
			"value": "BTCUSDT"
		}],
		supported_resolutions: ["1", "3", "5", "15", "30", "60", "120", "240", "360", "720", "1D", "1W", "1M"],
		"supports_timescale_marks": false
	}), 0);

};

Datafeeds.UDFCompatibleDatafeed.prototype.onReady = function (callback) {
	var that = this;
	if (this._configuration) {
		setTimeout(function () {
			callback(that._configuration);
		}, 0);
	} else {
		this.on('configuration_ready', function () {
			callback(that._configuration);
		});
	}
};

Datafeeds.UDFCompatibleDatafeed.prototype._setupWithConfiguration = function (configurationData) {
	this._configuration = configurationData;

	if (!configurationData.exchanges) {
		configurationData.exchanges = [];
	}

	//	@obsolete; remove in 1.5
	var supportedResolutions = configurationData.supported_resolutions || configurationData.supportedResolutions;
	configurationData.supported_resolutions = supportedResolutions;

	//	@obsolete; remove in 1.5
	var symbolsTypes = configurationData.symbols_types || configurationData.symbolsTypes;
	configurationData.symbols_types = symbolsTypes;

	if (!configurationData.supports_search && !configurationData.supports_group_request) {
		throw 'Unsupported datafeed configuration. Must either support search, or support group request';
	}

	if (!configurationData.supports_search) {
		this._symbolSearch = new Datafeeds.SymbolSearchComponent(this);
	}

	if (configurationData.supports_group_request) {
		//	this component will call onInitialized() by itself
		this._symbolsStorage = new Datafeeds.SymbolsStorage(this);
	} else {
		this.onInitialized();
	}

	this._fireEvent('configuration_ready');
	this._logMessage('Initialized with ' + JSON.stringify(configurationData));
};

//	===============================================================================================================================
//	The functions set below is the implementation of JavaScript API.

Datafeeds.UDFCompatibleDatafeed.prototype.getMarks = function (symbolInfo, rangeStart, rangeEnd, onDataCallback, resolution) {
	if (this._configuration.supports_marks) {
		this._send(this._datafeedURL + '/marks', {
			symbol: symbolInfo.ticker.toUpperCase(),
			from: rangeStart,
			to: rangeEnd,
			resolution: resolution
		})
			.then(function (response) {
				onDataCallback(JSON.parse(response));
			})
			.catch(function () {
				onDataCallback([]);
			});
	}
};

Datafeeds.UDFCompatibleDatafeed.prototype.getTimescaleMarks = function (symbolInfo, rangeStart, rangeEnd, onDataCallback, resolution) {
	if (this._configuration.supports_timescale_marks) {
		this._send(this._datafeedURL + '/timescale_marks', {
			symbol: symbolInfo.ticker.toUpperCase(),
			from: rangeStart,
			to: rangeEnd,
			resolution: resolution
		})
			.then(function (response) {
				onDataCallback(JSON.parse(response));
			})
			.catch(function () {
				onDataCallback([]);
			});
	}
};

Datafeeds.UDFCompatibleDatafeed.prototype.searchSymbols = function (searchString, exchange, type, onResultReadyCallback) {
	var MAX_SEARCH_RESULTS = 30;

	onResultReadyCallback([]);
	return;
	if (!this._configuration) {
		onResultReadyCallback([]);
		return;
	}

	if (this._configuration.supports_search) {

		this._send(this._datafeedURL + '/search', {
			limit: MAX_SEARCH_RESULTS,
			query: searchString.toUpperCase(),
			type: type,
			exchange: exchange
		})
			.then(function (response) {
				var data = JSON.parse(response);

				for (var i = 0; i < data.length; ++i) {
					if (!data[i].params) {
						data[i].params = [];
					}
				}

				if (typeof data.s == 'undefined' || data.s != 'error') {
					onResultReadyCallback(data);
				} else {
					onResultReadyCallback([]);
				}

			})
			.catch(function (reason) {
				onResultReadyCallback([]);
			});
	} else {

		if (!this._symbolSearch) {
			throw 'Datafeed error: inconsistent configuration (symbol search)';
		}

		var searchArgument = {
			searchString: searchString,
			exchange: exchange,
			type: type,
			onResultReadyCallback: onResultReadyCallback
		};

		if (this._initializationFinished) {
			this._symbolSearch.searchSymbols(searchArgument, MAX_SEARCH_RESULTS);
		} else {

			var that = this;

			this.on('initialized', function () {
				that._symbolSearch.searchSymbols(searchArgument, MAX_SEARCH_RESULTS);
			});
		}
	}
};

Datafeeds.UDFCompatibleDatafeed.prototype._symbolResolveURL = '/symbols';

//	BEWARE: this function does not consider symbol's exchange
Datafeeds.UDFCompatibleDatafeed.prototype.resolveSymbol = function (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
	var localSymbol = store.state.curCoin
    var pricescale = Math.pow(10, localSymbol.priceDecimal)
	// var volume_precision = localSymbol.number_decimals
	setTimeout(function () {
		onSymbolResolvedCallback({
			symbol: symbolName,
			has_daily: true,
			ticker: symbolName,
			has_weekly_and_monthly: true,
			has_intraday: true,
			session: "24x7",
			timezone: "Asia/Shanghai",
			description: symbolName,
			supported_resolutions: ["1", "3", "5", "15", "30", "60", "120", "240", "360", "720", "1D", "1W", "1M"],
			type: "1",
			intraday_multipliers: ["1", "3", "5", "15", "30", "60", "120", "240", "360", "720", "1D", "1W", "1M"],
			has_fractional_volume: false,
			// "exchange-listed": symbolName,
			full_name: "",
			has_empty_bars: false,
			// "exchange-traded": symbolName,
			minmove2: 0,
			"name": symbolName,
			minmov: 1,
			// exchange: "etf.top",
			pricescale: pricescale,
			volume_precision: 0,
			data_status: "pulsed", //streaming(实时)endofday(已收盘)pulsed(脉冲)delayed_streaming(延迟流动中)
			force_session_rebuild: false,
			has_no_volume: false
		});
	}, 0)
};

Datafeeds.UDFCompatibleDatafeed.prototype._historyURL = '/';

Datafeeds.UDFCompatibleDatafeed.prototype.getBars = function (symbolInfo, resolution, rangeStartDate, rangeEndDate, onDataCallback, onErrorCallback, first) {
	//	timestamp sample: 1399939200
	// debugger
	if (rangeStartDate > 0 && (rangeStartDate + '').length > 10) {
		throw ['Got a JS time instead of Unix one.', rangeStartDate, rangeEndDate];
	}


	var that = this;
	// const from = rangeStartDate * 1000;
	// const to = rangeEndDate * 1000;
	var range = filteringTime(resolution) * 1000

	// var requestStartTime = Date.now();
    let timeline = resolution == '1W' ? '1week' : resolution == '1D' ? '1day' : resolution > 60 ? `${parseInt(resolution) / 60}hour` : resolution + 'min'
	this._send(this._datafeedURL, {
		key: symbolInfo.ticker,
		timeline,
        length: 500
	}).then(function (response) {
		var meta = {
			version: that._protocolVersion,
			noData: false
		}
		meta.noData = response.data.length > 0 ? false : true;
		var bars = formatData(response.data, resolution);
		// var nextTime = bars[0].time;
		// if(bars[0] > bars[1]){
		// 	bars.reverse();
		// }
		bars.reverse();
		var startTime = bars[0] && bars[0].time;
		if (!first) {
			if (rangeStartDate < startTime / 1000) {
				bars = [];
				meta.noData = true;
				// meta.nextTime = nextTime;
			}
		}
		// console.log(bars,meta)
		onDataCallback(bars, meta);
	})
		.catch(function (arg) {
			console.warn(['getBars(): HTTP error', arg]);

			if (!!onErrorCallback) {
				onErrorCallback('network error: ' + JSON.stringify(arg));
			}
		});
};

Datafeeds.UDFCompatibleDatafeed.prototype.subscribeBars = function (symbolInfo, resolution, onRealtimeCallback, listenerGUID) {
	this._barsPulseUpdater.subscribeDataListener(symbolInfo, resolution, onRealtimeCallback, listenerGUID);
};

Datafeeds.UDFCompatibleDatafeed.prototype.unsubscribeBars = function (listenerGUID) {
	this._barsPulseUpdater.unsubscribeDataListener(listenerGUID);
};

Datafeeds.UDFCompatibleDatafeed.prototype.calculateHistoryDepth = function (period, resolutionBack, intervalBack) {
};

Datafeeds.UDFCompatibleDatafeed.prototype.getQuotes = function (symbols, onDataCallback, onErrorCallback) {
	this._send(this._datafeedURL + '/quotes', { symbols: symbols })
		.then(function (response) {
			var data = JSON.parse(response);
			if (data.s == 'ok') {
				//	JSON format is {s: "status", [{s: "symbol_status", n: "symbol_name", v: {"field1": "value1", "field2": "value2", ..., "fieldN": "valueN"}}]}
				if (onDataCallback) {
					onDataCallback(data.d);
				}
			} else {
				if (onErrorCallback) {
					onErrorCallback(data.errmsg);
				}
			}
		})
		.catch(function (arg) {
			if (onErrorCallback) {
				onErrorCallback('network error: ' + arg);
			}
		});
};

Datafeeds.UDFCompatibleDatafeed.prototype.subscribeQuotes = function (symbols, fastSymbols, onRealtimeCallback, listenerGUID) {
	this._quotesPulseUpdater.subscribeDataListener(symbols, fastSymbols, onRealtimeCallback, listenerGUID);
};

Datafeeds.UDFCompatibleDatafeed.prototype.unsubscribeQuotes = function (listenerGUID) {
	this._quotesPulseUpdater.unsubscribeDataListener(listenerGUID);
};

//	==================================================================================================================================================
//	==================================================================================================================================================
//	==================================================================================================================================================

/*
	It's a symbol storage component for ExternalDatafeed. This component can
	  * interact to UDF-compatible datafeed which supports whole group info requesting
	  * do symbol resolving -- return symbol info by its name
*/
Datafeeds.SymbolsStorage = function (datafeed) {
	this._datafeed = datafeed;

	this._exchangesList = ['NYSE', 'FOREX', 'AMEX'];
	this._exchangesWaitingForData = {};
	this._exchangesDataCache = {};

	this._symbolsInfo = {};
	this._symbolsList = [];

	this._requestFullSymbolsList();
};

Datafeeds.SymbolsStorage.prototype._requestFullSymbolsList = function () {

	var that = this;
	var datafeed = this._datafeed;

	for (var i = 0; i < this._exchangesList.length; ++i) {

		var exchange = this._exchangesList[i];

		if (this._exchangesDataCache.hasOwnProperty(exchange)) {
			continue;
		}

		this._exchangesDataCache[exchange] = true;

		this._exchangesWaitingForData[exchange] = 'waiting_for_data';

		this._datafeed._send(this._datafeed._datafeedURL + '/symbol_info', {
			group: exchange
		})
			.then(function (exchange) {
				return function (response) {
					that._onExchangeDataReceived(exchange, JSON.parse(response));
					that._onAnyExchangeResponseReceived(exchange);
				};
			}(exchange)) //jshint ignore:line
			.catch(function (exchange) {
				return function (reason) {
					that._onAnyExchangeResponseReceived(exchange);
				};
			}(exchange)); //jshint ignore:line
	}
};

Datafeeds.SymbolsStorage.prototype._onExchangeDataReceived = function (exchangeName, data) {

	function tableField(data, name, index) {
		return data[name] instanceof Array ?
			data[name][index] :
			data[name];
	}

	try {
		for (var symbolIndex = 0; symbolIndex < data.symbol.length; ++symbolIndex) {

			var symbolName = data.symbol[symbolIndex];
			var listedExchange = tableField(data, 'exchange-listed', symbolIndex);
			var tradedExchange = tableField(data, 'exchange-traded', symbolIndex);
			var fullName = tradedExchange + ':' + symbolName;

			//	This feature support is not implemented yet
			//	var hasDWM = tableField(data, "has-dwm", symbolIndex);

			var hasIntraday = tableField(data, 'has-intraday', symbolIndex);

			var tickerPresent = typeof data.ticker != 'undefined';

			var symbolInfo = {
				name: symbolName,
				base_name: [listedExchange + ':' + symbolName],
				description: tableField(data, 'description', symbolIndex),
				full_name: fullName,
				legs: [fullName],
				has_intraday: hasIntraday,
				has_no_volume: tableField(data, 'has-no-volume', symbolIndex),
				listed_exchange: listedExchange,
				exchange: tradedExchange,
				minmov: tableField(data, 'minmovement', symbolIndex) || tableField(data, 'minmov', symbolIndex),
				minmove2: tableField(data, 'minmove2', symbolIndex) || tableField(data, 'minmov2', symbolIndex),
				fractional: tableField(data, 'fractional', symbolIndex),
				pointvalue: tableField(data, 'pointvalue', symbolIndex),
				pricescale: tableField(data, 'pricescale', symbolIndex),
				type: tableField(data, 'type', symbolIndex),
				session: tableField(data, 'session-regular', symbolIndex),
				ticker: tickerPresent ? tableField(data, 'ticker', symbolIndex) : symbolName,
				timezone: tableField(data, 'timezone', symbolIndex),
				supported_resolutions: tableField(data, 'supported-resolutions', symbolIndex) || this._datafeed.defaultConfiguration().supported_resolutions,
				force_session_rebuild: tableField(data, 'force-session-rebuild', symbolIndex) || false,
				has_daily: tableField(data, 'has-daily', symbolIndex) || true,
				intraday_multipliers: tableField(data, 'intraday-multipliers', symbolIndex) || ['1', '5', '15', '30', '60'],
				has_fractional_volume: tableField(data, 'has-fractional-volume', symbolIndex) || false,
				has_weekly_and_monthly: tableField(data, 'has-weekly-and-monthly', symbolIndex) || false,
				has_empty_bars: tableField(data, 'has-empty-bars', symbolIndex) || false,
				volume_precision: tableField(data, 'volume-precision', symbolIndex) || 0
			};

			this._symbolsInfo[symbolInfo.ticker] = this._symbolsInfo[symbolName] = this._symbolsInfo[fullName] = symbolInfo;
			this._symbolsList.push(symbolName);
		}
	}
	catch (error) {
		throw 'API error when processing exchange `' + exchangeName + '` symbol #' + symbolIndex + ': ' + error;
	}
};

Datafeeds.SymbolsStorage.prototype._onAnyExchangeResponseReceived = function (exchangeName) {

	delete this._exchangesWaitingForData[exchangeName];

	var allDataReady = Object.keys(this._exchangesWaitingForData).length === 0;

	if (allDataReady) {
		this._symbolsList.sort();
		this._datafeed._logMessage('All exchanges data ready');
		this._datafeed.onInitialized();
	}
};

//	BEWARE: this function does not consider symbol's exchange
Datafeeds.SymbolsStorage.prototype.resolveSymbol = function (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
	var that = this;

	setTimeout(function () {
		if (!that._symbolsInfo.hasOwnProperty(symbolName)) {
			onResolveErrorCallback('invalid symbol');
		} else {
			onSymbolResolvedCallback(that._symbolsInfo[symbolName]);
		}
	}, 0);
};

//	==================================================================================================================================================
//	==================================================================================================================================================
//	==================================================================================================================================================

/*
	It's a symbol search component for ExternalDatafeed. This component can do symbol search only.
	This component strongly depends on SymbolsDataStorage and cannot work without it. Maybe, it would be
	better to merge it to SymbolsDataStorage.
*/

Datafeeds.SymbolSearchComponent = function (datafeed) {
	this._datafeed = datafeed;
};

//	searchArgument = { searchString, onResultReadyCallback}
Datafeeds.SymbolSearchComponent.prototype.searchSymbols = function (searchArgument, maxSearchResults) {

	if (!this._datafeed._symbolsStorage) {
		throw 'Cannot use local symbol search when no groups information is available';
	}

	var symbolsStorage = this._datafeed._symbolsStorage;

	var results = []; // array of WeightedItem { item, weight }
	var queryIsEmpty = !searchArgument.searchString || searchArgument.searchString.length === 0;
	var searchStringUpperCase = searchArgument.searchString.toUpperCase();

	for (var i = 0; i < symbolsStorage._symbolsList.length; ++i) {
		var symbolName = symbolsStorage._symbolsList[i];
		var item = symbolsStorage._symbolsInfo[symbolName];

		if (searchArgument.type && searchArgument.type.length > 0 && item.type != searchArgument.type) {
			continue;
		}

		if (searchArgument.exchange && searchArgument.exchange.length > 0 && item.exchange != searchArgument.exchange) {
			continue;
		}

		var positionInName = item.name.toUpperCase().indexOf(searchStringUpperCase);
		var positionInDescription = item.description.toUpperCase().indexOf(searchStringUpperCase);

		if (queryIsEmpty || positionInName >= 0 || positionInDescription >= 0) {
			var found = false;
			for (var resultIndex = 0; resultIndex < results.length; resultIndex++) {
				if (results[resultIndex].item == item) {
					found = true;
					break;
				}
			}

			if (!found) {
				var weight = positionInName >= 0 ? positionInName : 8000 + positionInDescription;
				results.push({ item: item, weight: weight });
			}
		}
	}

	searchArgument.onResultReadyCallback(
		results
			.sort(function (weightedItem1, weightedItem2) {
				return weightedItem1.weight - weightedItem2.weight;
			})
			.map(function (weightedItem) {
				var item = weightedItem.item;
				return {
					symbol: item.name,
					full_name: item.full_name,
					description: item.description,
					exchange: item.exchange,
					params: [],
					type: item.type,
					ticker: item.name
				};
			})
			.slice(0, Math.min(results.length, maxSearchResults))
	);
};

//	==================================================================================================================================================
//	==================================================================================================================================================
//	==================================================================================================================================================

/*
	This is a pulse updating components for ExternalDatafeed. They emulates realtime updates with periodic requests.
*/

Datafeeds.DataPulseUpdater = function (datafeed, updateFrequency) {
	this._datafeed = datafeed;
	this._subscribers = {};

	this._requestsPending = 0;
	var that = this;
	var timer = null;

	var update = function (bar) {
		if (that._requestsPending > 0) {
			return;
		}
		for (var listenerGUID in that._subscribers) {
			var subscriptionRecord = that._subscribers[listenerGUID];
			var resolution = subscriptionRecord.resolution;
			var datesRangeRight = parseInt((new Date().valueOf()) / 1000);

			//	BEWARE: please note we really need 2 bars, not the only last one
			//	see the explanation below. `10` is the `large enough` value to work around holidays
			var datesRangeLeft = datesRangeRight - that.periodLengthSeconds(resolution, 10);

			that._requestsPending++;
			if (datesRangeRight)
				(function (_subscriptionRecord) {
					function subData(bars) {
						that._requestsPending--;
						//	means the subscription was cancelled while waiting for data
						if (!that._subscribers.hasOwnProperty(listenerGUID)) {
							return;
						}

						if (bars.length === 0) {
							return;
						}
						var lastBar = bars[bars.length - 1];

						if (!isNaN(_subscriptionRecord.lastBarTime) && lastBar.time < _subscriptionRecord.lastBarTime) {
							return;
						}

						var subscribers = _subscriptionRecord.listeners;

						//	BEWARE: this one isn't working when first update comes and this update makes a new bar. In this case
						//	_subscriptionRecord.lastBarTime = NaN
						var isNewBar = !isNaN(_subscriptionRecord.lastBarTime) && lastBar.time > _subscriptionRecord.lastBarTime;

						//	Pulse updating may miss some trades data (ie, if pulse period = 10 secods and new bar is started 5 seconds later after the last update, the
						//	old bar's last 5 seconds trades will be lost). Thus, at fist we should broadcast old bar updates when it's ready.
						if (isNewBar) {

							if (bars.length < 2) {
								throw 'Not enough bars in history for proper pulse update. Need at least 2.';
							}

							var previousBar = bars[bars.length - 2];
								subscribers(previousBar);
						}

						_subscriptionRecord.lastBarTime = lastBar.time;
						subscribers(lastBar);
					}
					// if (bar) {
					// 	subData([bar])
					// 	return
					// }
					that._datafeed.getBars(_subscriptionRecord.symbolInfo, resolution, datesRangeLeft, datesRangeRight, subData,
						//	on error
						function (err) {
							that._requestsPending--;
							if (isNaN(datesRangeLeft)) throw err
						});
				})(subscriptionRecord); //jshint ignore:line

		}
	};

	if (window.ws) {
		store.subscribe(mutation => {
			if (mutation.type === 'setKline') {
				if (timer) clearInterval(timer) // 计时器删除
				const symbol = mutation.payload.symbol
                const timelines = {
                	"1min" : 1,
                	"5min" : 5,
                	"15min" : 15,
                	"30min" : 30,
                    "60min" : 60,
                	"4hour" : 240,
                    "1day" : 1440,
                    "1week" : 10080
                }
				const resolution = timelines[mutation.payload.resolution]
				const data = mutation.payload.data
				if (Array.isArray(data)) {
					// 数据处理...
					const bar = {
						time: data[0] * 1000,
						close: +data[4],
						open: +data[1],
						high: +data[2],
						low: +data[3],
						volume: Number(data[5])
					}
					let period = resolution;
					if (parseInt(resolution) === 24 * 60) {
					  period = "1D";
					} else if (parseInt(resolution) === 24 * 60 * 7) {
					  period = "1W";
					}
					for (var listenerGUID in that._subscribers) {
						var subscriptionRecord = that._subscribers[listenerGUID];
						var subscribers = subscriptionRecord.listeners;
					
						if(listenerGUID === symbol+'_'+period){
							if (!isNaN(subscriptionRecord.lastBarTime) && bar.time < subscriptionRecord.lastBarTime) {
								// console.log(bar.time, subscriptionRecord.lastBarTime);
								return;
							}
							// console.log('common: ', subscriptionRecord);
							subscriptionRecord.lastBarTime = bar.time;
							subscribers(bar);
						}
					}
					// console.log(mutation.payload)
				}
			}
		})

		function start() {
			if (timer) clearInterval(timer) // 计时器删除
			if (typeof updateFrequency != 'undefined' && updateFrequency > 0) {
				timer = setInterval(update, updateFrequency);
			}
		}
		// 监听是否关闭启动轮询
		setTimeout(() => {
			start()
			if (window.ws.socket) {
				window.ws.socket.addEventListener('open', ()=>{
					// console.log('open');
					if (timer&&window.ws.socket.readyState === 1) clearInterval(timer)
				})
			}
		}, 50)
	}

};

Datafeeds.DataPulseUpdater.prototype.unsubscribeDataListener = function (listenerGUID) {
	this._datafeed._logMessage('Unsubscribing ' + listenerGUID);
	delete this._subscribers[listenerGUID];
};

Datafeeds.DataPulseUpdater.prototype.subscribeDataListener = function (symbolInfo, resolution, newDataCallback, listenerGUID) {

	this._datafeed._logMessage('Subscribing ' + listenerGUID);

	var key = symbolInfo.name + ', ' + resolution;
	if (!this._subscribers.hasOwnProperty(listenerGUID)) {

		this._subscribers[listenerGUID] = {
			symbolInfo: symbolInfo,
			resolution: resolution,
			lastBarTime: NaN,
			listeners: newDataCallback
		};
	}
};

Datafeeds.DataPulseUpdater.prototype.periodLengthSeconds = function (resolution, requiredPeriodsCount) {
	var daysCount = 0;

	if (resolution.indexOf('D') > -1) {
		daysCount = requiredPeriodsCount;
	} else if (resolution.indexOf('M') > -1) {
		daysCount = 31 * requiredPeriodsCount;
	} else if (resolution.indexOf('W') > -1) {
		daysCount = 7 * requiredPeriodsCount;
	} else {
		daysCount = requiredPeriodsCount * resolution / (24 * 60);
	}

	return daysCount * 24 * 60 * 60;
};

Datafeeds.QuotesPulseUpdater = function (datafeed) {
	this._datafeed = datafeed;
	this._subscribers = {};
	this._updateInterval = 60 * 1000;
	this._fastUpdateInterval = 10 * 1000;
	this._requestsPending = 0;

	var that = this;

	setInterval(function () {
		that._updateQuotes(function (subscriptionRecord) { return subscriptionRecord.symbols; });
	}, this._updateInterval);

	setInterval(function () {
		that._updateQuotes(function (subscriptionRecord) { return subscriptionRecord.fastSymbols.length > 0 ? subscriptionRecord.fastSymbols : subscriptionRecord.symbols; });
	}, this._fastUpdateInterval);
};

Datafeeds.QuotesPulseUpdater.prototype.subscribeDataListener = function (symbols, fastSymbols, newDataCallback, listenerGUID) {
	if (!this._subscribers.hasOwnProperty(listenerGUID)) {
		this._subscribers[listenerGUID] = {
			symbols: symbols,
			fastSymbols: fastSymbols,
			listeners: []
		};
	}

	this._subscribers[listenerGUID].listeners.push(newDataCallback);
};

Datafeeds.QuotesPulseUpdater.prototype.unsubscribeDataListener = function (listenerGUID) {
	delete this._subscribers[listenerGUID];
};

Datafeeds.QuotesPulseUpdater.prototype._updateQuotes = function (symbolsGetter) {
	if (this._requestsPending > 0) {
		return;
	}

	var that = this;
	for (var listenerGUID in this._subscribers) {
		this._requestsPending++;

		var subscriptionRecord = this._subscribers[listenerGUID];
		this._datafeed.getQuotes(symbolsGetter(subscriptionRecord),

			// onDataCallback
			(function (subscribers, guid) {
				return function (data) {
					that._requestsPending--;

					// means the subscription was cancelled while waiting for data
					if (!that._subscribers.hasOwnProperty(guid)) {
						return;
					}
					subscribers(data);
				};
			}(subscriptionRecord.listeners, listenerGUID)), //jshint ignore:line
			// onErrorCallback
			function (error) {
				that._requestsPending--;
			}); //jshint ignore:line
	}
};


export default Datafeeds;